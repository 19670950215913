import React from "react"
import styled from "styled-components"
import { Container } from "@theme-ui/components"
import CallToAction from "./callToAction"

const CtaBlock = ({ content }) => {
  const theme = content.backgroundColor.hex === "#ffffff" ? "light" : "dark"
  return (
    <Wrapper color={content.backgroundColor.hex}>
      <Container>
        <CallToAction cta={content.callToAction} theme={theme} />
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${props => props.color};
  padding: 0.25rem 0;
  text-align: center;
`

export default CtaBlock
