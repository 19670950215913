import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { ItemsList } from "./utils"
import I18nLink from "./i18nLink"
import { Body } from "./typography"
import { LanguageContext } from "../context/langContext"

const StoriesList = ({ stories, locale }) => (
  <CustomList>
    {stories.map(story => (
      <li key={story.id}>
        <I18nLink locale={locale} slug={`stories/` + story.slug}>
          {story.heroImage && (
            <img src={story.heroImage.sizes.src} alt={story.heroImage.alt} />
          )}
          <div>
            <Body>"{story.title}"</Body>
            <Body>
              <strong>{story.companyName}</strong>, {story.country}
            </Body>
          </div>
          <LanguageContext.Consumer>
            {t => (
              <Body color={DesignTokens.colors.primary[500]}>
                {t.discoverMore}
              </Body>
            )}
          </LanguageContext.Consumer>
        </I18nLink>
      </li>
    ))}
  </CustomList>
)
const CustomList = styled(ItemsList)`
  margin-bottom: 10rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  & > li {
    width: calc(96% / 3);
    margin: 8% 2% 0 0;
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-child(-n + 3) {
      margin-top: 0;
    }
    &:nth-child(even) {
      margin-right: 2%;
    }
    &:nth-child(3n + 3) {
      margin-right: 0;
    }
    a {
      img {
        border-radius: ${DesignTokens.borderRadius};
      }
      display: block;
      text-decoration: none;
      color: ${DesignTokens.colors.black};
      &:hover {
        color: ${DesignTokens.colors.primary[500]};
        img {
          opacity: 0.8;
        }
      }
      & > * {
        margin-bottom: ${DesignTokens.spacing[5]};
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      width: 100%;
      margin: 0 0 3rem 0;
      img {
        width: 100%;
      }
    }
  }
`

export default StoriesList
