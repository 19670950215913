import React from "react"
import { Box, Container, Flex, Image, Text, Grid } from "@theme-ui/components"

const LogoBlock = ({ images }) => (
  <Box>
    <Container py={[2, 2]}>
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {images.map(logo => (
          <Box
            sx={{
              marginX: 3,
              marginY: 3,
              maxWidth: ["160px"],
              maxHeight: ["60px", "80px"],
            }}
            key={logo.originalId}
          >
            <Image
              src={logo.url}
              sx={{
                maxWidth: "inherit",
                maxHeight: "inherit",
                objectFit: "contain",
                width: "auto",
                height: "auto",
              }}
            />
          </Box>
        ))}
      </Flex>
    </Container>
  </Box>
)

export default LogoBlock
