import React from "react"
import styled from "styled-components"
import { IsMobile, IsDesktop } from "./utils"
import { Heading, Caption } from "./typography"
import Nav from "./nav"
import MobileNav from "./mobileNav"
import DesignTokens from "./designTokens"
import CallToAction from "./callToAction"
import background from "../images/hero-image.jpg"
import RichContentBlockText from "./richContentBlockText"
import { Box, Container } from "theme-ui"
import { ExternalLinkButtonTertiary } from "./button"
import { LanguageContext } from "../context/langContext"

const HomeHeader = ({
  title,
  subtitle,
  text,
  locale,
  callToAction,
  videoUrl,
  theme,
}) => (
  <Wrapper>
    <IsDesktop>
      <Nav locale={locale} />
    </IsDesktop>
    <IsMobile>
      <MobileNav locale={locale} />
    </IsMobile>
    <Container>
      <Box
        sx={{
          paddingTop: "6rem",
          paddingX: 0,
          paddingBottom: "7rem",
          width: ["100%", "100%", "100%", "75%", "60%"],
          "& > *": {
            marginBottom: 4,
            "&:last-child": {
              marginBottom: 0,
            },
          },
        }}
      >
        <RichContentBlockText html={title} />
        <Box
          dangerouslySetInnerHTML={{ __html: subtitle }}
          sx={{
            "h1,h2,h3,h4,h5,h6,p": {
              mb: 4,
            },
            "h1,h2": {
              fontSize: [4, 6],
            },
            "h3,h4,h5,h6": {
              fontSize: [3, 5],
            },
          }}
        />
        {text && (
          <Caption color={DesignTokens.colors.primary[500]}>{text}</Caption>
        )}
        <Box sx={{ "*": { mr: [3] } }}>
          {callToAction && <CallToAction cta={callToAction} theme={theme} />}
          {videoUrl && (
            <LanguageContext.Consumer>
              {t => (
                <ExternalLinkButtonTertiary
                  href={videoUrl}
                  target="blank"
                  rel="noopener nofollow"
                >
                  {t.watchVideo}
                </ExternalLinkButtonTertiary>
              )}
            </LanguageContext.Consumer>
          )}
        </Box>
      </Box>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div`
  background-color: ${DesignTokens.colors.secondary[500]};
  background-image: url(${background});
  background-size: cover;
  background-position: top center;
  color: ${DesignTokens.colors.white};
  @media screen and (max-width: 767px) {
    background-size: 400%;
    background-position: right;
  }
`

export default HomeHeader
