import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container } from "./utils"
import SectionHeader from "./sectionHeader"
import CallToAction from "./callToAction"
import StoriesList from "./storiesList"
import { LanguageContext, languages } from "../context/langContext"

const StoriesBlock = ({ content, locale }) => {
  const providerLanguage = languages[locale]
  return (
    <Wrapper>
      <CustomContainer>
        <SectionHeader
          label={content.label}
          title={content.title}
          text={content.text}
        />
        <LanguageContext.Provider value={providerLanguage}>
          <StoriesList stories={content.stories} locale={locale} />
        </LanguageContext.Provider>
        {content.callToAction && <CallToAction cta={content.callToAction} />}
      </CustomContainer>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  background-color: ${props => props.color};
  padding: 3rem 0;
  text-align: center;
`

const CustomContainer = styled(Container)`
  & > * {
    margin-bottom: ${DesignTokens.spacing[10]};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default StoriesBlock
