import React from "react"
import { Box, Container, Image } from "@theme-ui/components"

// Begin swiper
import { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js"
import "swiper/swiper-bundle.min.css"
import { Flex } from "theme-ui"
// End swiper

const ImageGallery = ({ images, fullWidth }) => {
  const Wrapper = fullWidth ? Box : Container
  console.log(images)
  return (
    <Wrapper
      sx={{
        position: "relative",
        backgroundColor: fullWidth ? "#efefef" : "ffffff",
      }}
    >
      <Swiper
        spaceBetween={fullWidth ? 0 : 16}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          896: {
            slidesPerView: 1,
          },
        }}
        pagination={{ clickable: true }}
        modules={[Pagination]}
        // autoHeight
        watchOverflow
      >
        {images.map(image => (
          <SwiperSlide key={image.originalId}>
            <Flex mb={3}>
              {image.mimeType === "video/mp4" && (
                <Box
                  sx={{
                    video: {
                      width: "100%",
                      height: "auto",
                    },
                  }}
                >
                  <video
                    autoPlay={
                      image.customData.autoplay === "true" ? true : false
                    }
                    loop={image.customData.autoplay === "true" ? true : false}
                    muted={image.customData.autoplay === "true" ? true : false}
                    playsInline={
                      image.customData.autoplay === "true" ? true : false
                    }
                    controls={
                      image.customData.autoplay === "true" ? false : true
                    }
                    src={image.url}
                  />
                </Box>
              )}
              {image.mimeType !== "video/mp4" && (
                <Image sx={{ width: "100%" }} src={image.url} alt="" />
              )}
            </Flex>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  )
}

export default ImageGallery
